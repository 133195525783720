@use '@angular/material' as mat;
@use "sass:map";
@use '../vars/vars.scss';
@use '../vars/colors/comparit.scss';

@mixin datepicker-theme($theme) {
  .mat-datepicker-content {
    .mat-calendar {
      box-shadow: vars.$shadow;
    }
    &.mat-primary {
      @include _datepicker-color($theme, primary);
    }
    &.mat-accent {
      @include _datepicker-color($theme, accent);
    }
    &.mat-warn {
      @include _datepicker-color($theme, warn);
    }
  }

  .mat-datepicker-toggle .mdc-icon-button[disabled] {
    opacity: 0.3;
  }
}

@mixin _datepicker-color($theme, $palette) {
  $contrastColor: #{map.get(comparit.$cp-contrast-light, 100)};

  @if $palette == 'primary' {
    $contrastColor: vars.$primaryContrast;
  } @else if $palette == 'accent' {
    $contrastColor: vars.$accentContrast;
  } @else if $palette == 'warn' {
    $contrastColor: vars.$warnContrast;
  }
  --mat-datepicker-calendar-date-selected-state-text-color: #{$contrastColor};
  --mat-datepicker-calendar-date-text-color: #{map.get(comparit.$cp-primary, 100)};
}
