@use "sass:map";
@use 'index';
@use '../../../libs/ui/src/scss/vars/colors/comparit.scss';
@use '../../../libs/ui/src/scss/vars/sizes/components.scss';

.smallform {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(
    #{map.get(components.$modal, height)} - #{map.get(components.$header, height)} - #{map.get(components.$container, height)}
  );
  z-index: 2;
}
