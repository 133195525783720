/**
 * Collection of comparit brand colors - taken from figma.
 * Do not use other colors unless agreed with others :)
*/
// primary
$cp-primary: (
  100: #1b1c4d,
  80: #1b1c4dcc,
  60: #1b1c4d99,
  40: #1b1c4d66,
  20: #1b1c4d33,
  8: #1b1c4d14,
);

$cp-primary-flat: (
  // used for illustrations
  80: #49496ecc,
  8: #49496e14
);

$cp-primary-hover: (
  100: #0c0e16,
);

$cp-primary-container: (
  // used for backgrounds, borders..
  100: #e4e4e4
);

// secondary
$cp-secondary: (
  100: #0ab7a7,
  80: #0ab7a7cc,
  60: #0ab7a799,
  40: #0ab7a766,
  20: #0ab7a733,
);

$cp-secondary-hover: (
  100: #459a8e,
);

// error
$cp-error: (
  100: #ff3d00,
  80: #ff3d00cc,
  60: #ff3d0099,
  20: #ff3d0033,
);

// highlight
$cp-highlight: (
  100: #e9e954,
);

$cp-highlight-mid: (
  100: #ebc603,
);

$cp-contrast-light: (
  100: #ffffff,
);

$cp-contrast-dark: (
  100: #000000,
);

$cp-shadow: (
  100: #1b1c4d,
);
