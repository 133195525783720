@use "sass:map";
@use "vars/colors/comparit";

@mixin btn-large {
  padding: 15px 56px 15px 56px !important;
  height: 48px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  min-width: 112px !important;
}

@mixin btn-small {
  padding: 8px 32px 8px 32px !important;
  height: 34px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  min-width: 112px !important;
}

@mixin btn-secondary {
  color: #{map.get(comparit.$cp-primary, 100)} !important;
  border: 2px solid #{map.get(comparit.$cp-primary, 100)} !important;
  border-radius: 50px !important;
}

@mixin btn-error {
  color: #{map.get(comparit.$cp-error, 100)} !important;
  border: 2px solid #{map.get(comparit.$cp-error, 100)} !important;
  border-radius: 50px !important;
}

@mixin btn-tertiary {
  // empty suffices
}

@mixin btn-header-mixin {
  width: 300px !important;
  height: 48px !important;
  padding: 12px !important;
  gap: 10px !important;
  border-radius: 4px !important;
}

.btn-primary-lg {
  @include btn-large();
}

.btn-primary-sm {
  @include btn-small();
}

.btn-secondary-lg {
  @include btn-large();
  @include btn-secondary();
}

.btn-secondary-sm {
  @include btn-small();
  @include btn-secondary();
}

.btn-tertiary-lg {
  @include btn-large();
  @include btn-tertiary();
}

.btn-tertiary-sm {
  @include btn-small();
  @include btn-tertiary();
}

.btn-error-lg {
  @include btn-large();
  @include btn-error();
}

.btn-error-sm {
  @include btn-small();
  @include btn-error();
}

.btn-header-selected {
  box-shadow: #{map.get(comparit.$cp-primary, 20)} 0px 4px 24px;
  border: 1.5px solid #{map.get(comparit.$cp-secondary, 100)} !important;
  @include btn-header-mixin();
}

.btn-header {
  border: 1px solid #{map.get(comparit.$cp-primary, 20)} !important;
  background: transparent !important;
  @include btn-header-mixin();
}

button:disabled {
  cursor: not-allowed !important;
}

.toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    border: 2px solid #{map.get(comparit.$cp-primary, 100)};
    display: block;
    border-radius: 100px;
    position: relative;
  }

  .disabled-label {
    pointer-events: none !important;
    border-color: gray;
  }

  .disabled-label:after {
    background: gray;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #{map.get(comparit.$cp-primary, 100)};
    border-radius: 90px;
    transition: 0.5s;
  }

  input:checked + label {
    border: 2px solid #{map.get(comparit.$cp-secondary, 100)};
  }

  input:checked + label:after {
    background: #{map.get(comparit.$cp-secondary, 100)};
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 20px;
  }
}

// left-over code from abandoned wip, might be handy (or deletable) in the future
// TODO aborted working on buttons with svg icons + their themes, takes more time for complicated svg, should be in its own ticket
// TODO use icons.sccs mat-icon-size?
// @mixin btn-icon{
//   width: 48px !important;
//   height: 48px !important;
// }

// @mixin icon{
//   width: 24px !important;
//   height: 24px !important;
//   padding: 12px;
// }

// design name: "square button"
// .btn-fab-bordered{
//   @include btn-icon();
//   border-radius: 4px !important;
//   border: 1px solid #{map-get($cp-primary, 20)} !important;
// }

// .btn-fab{
//   @include btn-icon();
//   //padding: 0px !important;
//   //min-width: 48px !important;
//   //box-shadow: none !important;
//   //background-color: #{map-get($cp-secondary, 100)};
// }

// TODO find better option for theming svgs
// .btn-fab-icon-primary svg{
//   @include icon();
//   stroke: #{map-get($cp-primary, 100)} !important;
// }

// .btn-fab-icon-contrast-light svg{
//   stroke: #{map-get($cp-contrast-light, 100)} !important;
//   @include icon();
// }
