@use "sass:map";
@use '../scss/vars/vars.scss';
@use '../scss/vars/colors/comparit.scss';
@use '../assets/fonts/grotesk/include.scss' as grotesk;

html {
  --mdc-filled-button-container-shape: 50px !important;
  --mdc-outlined-button-container-shape: 50px !important;
  --mdc-outlined-button-outline-width: 2px;
  --mdc-text-button-container-shape: 50px !important;
  --mdc-chip-label-text-weight: 700;
}

.mat-mdc-progress-bar {
  border-radius: 5px;
  --mdc-linear-progress-active-indicator-height: 24px !important;
  --mdc-linear-progress-track-height: 24px !important;
  --mdc-linear-progress-track-shape: 5px !important;
  --mdc-linear-progress-track-color: #{map.get(comparit.$cp-primary, 8)} !important;

  .mdc-linear-progress__buffer {
    border: 1px solid #{map.get(comparit.$cp-primary, 20)};
  }
}

.mat-badge-content {
  background: #{map.get(comparit.$cp-highlight, 100)} !important;
  color: #{map.get(comparit.$cp-primary, 100)} !important;
}

.big-rounded-button {
  width: auto !important;
  height: auto !important;
  padding-block: 4px !important;
  padding-inline: 6px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role='img'] {
    width: 32px;
    height: 32px;
    font-size: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

/*
 * icon button
 */
button.mat-mdc-icon-button {
  font-size: unset;
}

.min-rounded-button {
  background-color: rgb(27, 28, 77, 0.08) !important;
  width: 24px !important;
  height: 24px !important;
  padding: 5px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role='img'] {
    width: 14px;
    height: 14px;
    font-size: 14px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }
}

.rounded-button {
  background-color: rgb(27, 28, 77, 0.08) !important;
  width: 32px !important;
  height: 32px !important;
  padding: 4px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  .mat-mdc-button-touch-target {
    width: 32px !important;
    height: 32px !important;
  }
}

/**
 * Table styling
 */

mat-table.mat-mdc-table {
  background: transparent;
  background-color: unset;
}

mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  padding-left: 0 !important;
}

mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 0 !important;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/**
 * Accordions styling
 */
.mat-accordion .mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: vars.$shadow;
}

.mat-expansion-panel-header-title {
  --mat-expansion-header-text-color: #{map.get(comparit.$cp-primary, 100)};
}

.mat-accordion {
  .mat-expansion-panel {
    box-shadow: vars.$shadow;
    margin: 16px 0;
  }

  .mat-expansion-panel-header {
    @extend .font-grotesk-s1 !optional;
    padding-bottom: 32px;
    padding-top: 32px;
  }

  .mat-expansion-indicator::after {
    font-size: 300%;
    padding: 6px;
    border-color: #{map.get(comparit.$cp-primary, 100)};
    margin-top: -8px;
  }
}

/**
 * Tabs
 */

.mat-mdc-tab-header {
  --mat-tab-header-label-text-size: 20px;
  --mat-tab-header-label-text-line-height: 28px;
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-label-text-letter-spacing: 0;
}

.mat-mdc-tab-group2,
.mat-mdc-tab-nav-bar2 {
  // TODO can this be removed?
  //--mdc-tab-indicator-active-indicator-color: #009688;
  //--mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  //--mat-tab-header-pagination-icon-color: #000;
  //--mat-tab-header-inactive-label-text-color: #1B1C4D;
  //--mat-tab-header-active-label-text-color: #009688;
  //--mat-tab-header-active-ripple-color: #009688;
  //--mat-tab-header-inactive-ripple-color: #009688;
  //--mat-tab-header-inactive-focus-label-text-color: #1B1C4D;
  //--mat-tab-header-inactive-hover-label-text-color: #1B1C4D;
  //--mat-tab-header-active-focus-label-text-color: #009688;
  //--mat-tab-header-active-hover-label-text-color: #009688;
  //--mat-tab-header-active-focus-indicator-color: #009688;
  //--mat-tab-header-active-hover-indicator-color: #009688;
}

/**
 * Chips
 */
.mat-mdc-standard-chip {
  --mdc-chip-label-text-size: 16px;
  --mdc-chip-label-text-weight: 700;
}

/**
 * mat-menu
 */
.mat-mdc-menu-panel.mat-elevation-z8 {
  box-shadow: vars.$shadow;
}

/**
 * Mat-error
 */
mat-error {
  font-size: 12px;
}

mat-form-field mat-error {
  margin: 0 -13px; // remove spacing from form mat-form-field errors
}

mat-form-field {
  width: 100%;
}

/**
 * Modal
 */

.mdc-dialog {
  .mdc-dialog__actions {
    padding: 0px 24px 20px 24px;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

/**
 * ICON / mat-icon
 */

mat-icon {
  stroke: #{map.get(comparit.$cp-primary, 100)}; // default
}

.icon-no-stroke {
  stroke: none !important;
}

/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon {
  color: #{map.get(comparit.$cp-contrast-light, 100)};

  svg * {
    stroke: #{map.get(comparit.$cp-contrast-light, 100)};
  }
}

.icon-fullfill-white {
  color: #{map.get(comparit.$cp-contrast-light, 100)};

  svg {
    stroke: none;

    path {
      stroke: #{map.get(comparit.$cp-contrast-light, 100)};
    }

    rect {
      fill: #{map.get(comparit.$cp-contrast-light, 100)};
    }
  }
}

.icon-sign-secondary {
  svg {
    stroke: none;

    path {
      stroke: #{map.get(comparit.$cp-secondary, 100)};
    }

    path:nth-child(3) {
      fill: #{map.get(comparit.$cp-secondary, 100)};
    }
  }
}

.icon-pending-primary {
  svg * {
    stroke: #{map.get(comparit.$cp-primary, 100)};
  }
}

.icon-sign-white {
  svg {
    stroke: none;

    path {
      stroke: #{map.get(comparit.$cp-contrast-light, 100)};
    }

    path:nth-child(3) {
      fill: #{map.get(comparit.$cp-contrast-light, 100)};
    }
  }
}

.icon-success {
  color: #{map.get(comparit.$cp-secondary, 100)};

  svg * {
    stroke: #{map.get(comparit.$cp-secondary, 100)};
  }
}

.cp-close-filled-red {
  svg {
    fill: #{map.get(comparit.$cp-error, 100)};

    circle {
      stroke: #{map.get(comparit.$cp-error, 100)};
    }
  }
}

.cp-close-filled-neutral {
  svg {
    fill: #{map.get(comparit.$cp-highlight-mid, 100)};

    circle {
      stroke: #{map.get(comparit.$cp-highlight-mid, 100)};
    }
  }
}

.icon-info-primary {
  stroke: none !important;

  svg {
    circle {
      stroke: #{map.get(comparit.$cp-primary, 100)};
    }

    path {
      fill: #{map.get(comparit.$cp-primary, 100)};
    }
  }
}

.icon-info-error {
  stroke: none !important;

  svg {
    circle {
      stroke: #{map.get(comparit.$cp-error, 100)};
    }

    path {
      fill: #{map.get(comparit.$cp-error, 100)};
    }
  }
}

.icon-advice-error {
  stroke: none !important;

  svg {
    circle {
      stroke: #{map.get(comparit.$cp-error, 100)};
    }

    path {
      fill: #{map.get(comparit.$cp-error, 100)};
    }
  }
}

/**
 *  Paginator
 */
.mat-mdc-paginator {
  background-color: transparent !important;
}
/**
 *  Tooltip
 */
.mdc-tooltip {
  white-space: pre-line;
  height: fit-content !important;
  max-height: none !important;
  position: relative !important;
}

.mdc-tooltip__surface {
  @extend .font-grotesk-s1 !optional;
  background-color: #{map.get(comparit.$cp-primary, 100)} !important;
  font-weight: 400;
  max-width: 300px !important;
  height: fit-content !important;
  max-height: none !important;
  display: inline-block !important;
  position: relative !important;
  padding: 8px !important;
}

.mdc-tooltip::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent;
}

.mat-mdc-tooltip-panel-below .mdc-tooltip::after {
  border-bottom-color: #{map.get(comparit.$cp-primary, 100)} !important;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.mat-mdc-tooltip-panel-above .mdc-tooltip::after {
  border-top-color: #{map.get(comparit.$cp-primary, 100)} !important;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
/**
 *  Tooltip End
 */

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #{map.get(comparit.$cp-secondary, 100)};

  .mdc-linear-progress__buffer-bar {
    --mdc-linear-progress-track-color: white;
  }

  .mdc-linear-progress__bar-inner {
    --mdc-linear-progress-active-indicator-height: 0.25rem;
  }

  .mdc-linear-progress__buffer {
    border: 0;
  }
}

/**
 * Text of inputs elements
 */
.mat-mdc-form-field {
  --mdc-outlined-text-field-input-text-color: #{map.get(comparit.$cp-primary, 100)};
  .mdc-floating-label--float-above {
    --mdc-outlined-text-field-label-text-color: #{map.get(comparit.$cp-primary, 100)};
  }
}

.mat-mdc-radio-button {
  --mdc-theme-text-primary-on-background: #{map.get(comparit.$cp-primary, 100)};
}

.mat-mdc-select {
  --mat-select-enabled-trigger-text-color: #{map.get(comparit.$cp-primary, 100)};
}

.mat-mdc-option {
  --mat-option-label-text-color: #{map.get(comparit.$cp-primary, 100)};
}
/**
 * Text of input elements End
 */
