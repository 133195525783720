@use '@angular/material' as mat;
@use "sass:map";
@use '../vars/vars.scss';
@use '../vars/colors/comparit.scss';

@mixin checkbox-theme($theme) {
  .mat-mdc-checkbox {
    &.mat-primary {
      @include _checkbox-color($theme, primary);
    }
    &.mat-accent {
      @include _checkbox-color($theme, accent);
    }
    &.mat-warn {
      @include _checkbox-color($theme, warn);
    }
  }

}

@mixin _checkbox-color($theme, $palette) {
  $contrastColor: #{map.get(comparit.$cp-contrast-light, 100)};

  @if $palette == 'primary' {
    $contrastColor: vars.$primaryContrast;
  } @else if $palette == 'accent' {
    $contrastColor: vars.$accentContrast;
  } @else if $palette == 'warn' {
    $contrastColor: vars.$warnContrast;
  }
  --mdc-checkbox-selected-checkmark-color: #{$contrastColor};
  --mdc-theme-text-primary-on-background: #{map.get(comparit.$cp-primary, 100)};
}
