@use "sass:list";
@mixin mat-icon-size($button-size, $icon-size) {
  width: $button-size !important;
  height: $button-size !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role='img'] {
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;

    svg {
      width: $icon-size;
      height: $icon-size;
    }
  }

  .mat-mdc-button-touch-target {
    width: $button-size !important;
    height: $button-size !important;
  }
}

$mat-icon-sizes: (
  '14': (
    20px,
    14px,
  ),
  '16': (
    26px,
    16px,
  ),
  '20': (
    32px,
    20px,
  ),
  '24': (
    40px,
    24px,
  ),
  '32': (
    50px,
    32px,
  ),
);

@each $name, $sizes in $mat-icon-sizes {
  .mat-icon-size-#{$name} {
    @include mat-icon-size(list.nth($sizes, 1), list.nth($sizes, 2));
  }
}
