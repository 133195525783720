@use '@angular/material' as mat;
@use "sass:map";
@use '../../vars/vars.scss';
@use '../../vars/colors/comparit.scss';

/**
* Angular material theme does not calculate colors matching to our design system.
* Therefore, we have to overwrite some colors (especially foreground, ripples..)
*/
@mixin _unelevated-button-variant($theme, $palette) {
  $contrastColor: #{map.get(comparit.$cp-contrast-light, 100)};

  @if $palette == 'primary' {
    $contrastColor: vars.$primaryContrast;
  } @else if $palette == 'accent' {
    $contrastColor: vars.$accentContrast;
  } @else if $palette == 'warn' {
    $contrastColor: vars.$warnContrast;
  }

  --mdc-filled-button-label-text-color: #{$contrastColor};
  --mat-filled-button-ripple-color: #{rgba($contrastColor, 0.1)};
  --mat-filled-button-state-layer-color: #{$contrastColor};
}

@mixin _raised-button-variant($theme, $palette) {
  $contrastColor: #{map.get(comparit.$cp-contrast-light, 100)};

  @if $palette == 'primary' {
    $contrastColor: vars.$primaryContrast;
  } @else if $palette == 'accent' {
    $contrastColor: vars.$accentContrast;
  } @else if $palette == 'warn' {
    $contrastColor: vars.$warnContrast;
  }

  --mdc-protected-button-label-text-color: #{$contrastColor};
  --mat-protected-button-ripple-color: #{rgba($contrastColor, 0.1)};
  --mat-protected-button-state-layer-color: #{$contrastColor};
}

@mixin _fab-color($theme, $palette) {
  $contrastColor: #{map.get(comparit.$cp-contrast-light, 100)};

  @if $palette == 'primary' {
    $contrastColor: vars.$primaryContrast;
  } @else if $palette == 'accent' {
    $contrastColor: vars.$accentContrast;
  } @else if $palette == 'warn' {
    $contrastColor: vars.$warnContrast;
  }

  --mat-fab-foreground-color: #{$contrastColor};
  --mat-fab-ripple-color: #{rgba($contrastColor, 0.1)};
  --mat-fab-state-layer-color: #{$contrastColor};
}

@mixin _fab-colors($theme) {
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-primary {
      @include _fab-color($theme, primary);
    }

    &.mat-accent {
      @include _fab-color($theme, accent);
    }

    &.mat-warn {
      @include _fab-color($theme, warn);
    }
  }
}

@mixin button-theme($theme) {
  .mat-mdc-unelevated-button {
    &.mat-primary {
      @include _unelevated-button-variant($theme, primary);
    }

    &.mat-accent {
      @include _unelevated-button-variant($theme, accent);
    }

    &.mat-warn {
      @include _unelevated-button-variant($theme, warn);
    }
  }

  .mat-mdc-raised-button {
    &.mat-primary {
      @include _raised-button-variant($theme, primary);
    }

    &.mat-accent {
      @include _raised-button-variant($theme, accent);
    }

    &.mat-warn {
      @include _raised-button-variant($theme, warn);
    }
  }

  @include _fab-colors($theme);

  html {
    @include _fab-colors($theme);
  }
}
