@use "sass:map";
@use '../assets/fonts/arimo/include.scss' as arimo;
@use '../assets/fonts/grotesk/include.scss' as grotesk;
@use 'theme';
@use 'matOverwrite';
@use 'icons.scss';
@use '../scss/vars/colors/comparit.scss';
@use 'components/buttons/button-styles';
@use 'vars/spacings/buttons.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

// enables to use colors globally and in tailwind config
:root {
  --cp-primary-100: #{map.get(comparit.$cp-primary, 100)};
  --cp-primary-80: #{map.get(comparit.$cp-primary, 80)};
  --cp-primary-60: #{map.get(comparit.$cp-primary, 60)};
  --cp-primary-40: #{map.get(comparit.$cp-primary, 40)};
  --cp-primary-20: #{map.get(comparit.$cp-primary, 20)};
  --cp-primary-8: #{map.get(comparit.$cp-primary, 8)};
  --cp-primary-flat-80: #{map.get(comparit.$cp-primary-flat, 80)};
  --cp-primary-flat-8: #{map.get(comparit.$cp-primary-flat, 8)};
  --cp-primary-hover-100: #{map.get(comparit.$cp-primary-hover, 100)};
  --cp-primary-container-100: #{map.get(comparit.$cp-primary-container, 100)};
  --cp-secondary-100: #{map.get(comparit.$cp-secondary, 100)};
  --cp-secondary-80: #{map.get(comparit.$cp-secondary, 80)};
  --cp-secondary-60: #{map.get(comparit.$cp-secondary, 60)};
  --cp-secondary-40: #{map.get(comparit.$cp-secondary, 40)};
  --cp-secondary-20: #{map.get(comparit.$cp-secondary, 20)};
  --cp-secondary-hover-100: #{map.get(comparit.$cp-secondary-hover, 100)};
  --cp-error-100: #{map.get(comparit.$cp-error, 100)};
  --cp-error-80: #{map.get(comparit.$cp-error, 80)};
  --cp-error-60: #{map.get(comparit.$cp-error, 60)};
  --cp-error-20: #{map.get(comparit.$cp-error, 20)};
  --cp-highlight-100: #{map.get(comparit.$cp-highlight, 100)};
  --cp-highlight-mid-100: #{map.get(comparit.$cp-highlight-mid, 100)};
  --cp-contrast-light-100: #{map.get(comparit.$cp-contrast-light, 100)};
  --cp-contrast-dark-100: #{map.get(comparit.$cp-contrast-dark, 100)};
  --cp-shadow-100: #{map.get(comparit.$cp-shadow, 100)};
  --fab-margin: #{map.get(buttons.$fab, margin)};
}

// default stroke, if none is defined in svg itself
.svg {
  stroke: black;
}

// fix for tailwind - see https://stackoverflow.com/questions/74621735/angular-material-15-with-tailwind-css-3-2-4-in-angular-project-mismatched
*,
::before,
::after {
  border-style: none;
}

* {
  -webkit-font-smoothing: antialiased;
}

.page-width-size {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 12px;
}

header,
.page-container {
  @extend .page-width-size;

  .form-container {
    max-width: 842px;
    margin: 0 auto;
  }
}

body {
  font-size: 16px;
}

body {
  font-family: 'Arimo', serif;
  color: #{map.get(comparit.$cp-primary, 100)};
  line-height: 1.5;
  font-weight: 400;
}

a {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.font-grotesk-s1 {
  font-size: 20px;
  font-family: 'SharpGrotesk', serif;
  line-height: 28px;
  font-weight: 500;
}

// 25% reduced
.font-grotesk-s1-print {
  font-size: 15px;
  font-family: 'SharpGrotesk', serif;
  line-height: 21px;
  font-weight: 500;
}

.info-tooltip {
  width: fit-content;
  padding: 4px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.vorgaben {
  background-color: #{map.get(comparit.$cp-highlight, 100)};
}

.info {
  color: #{map.get(comparit.$cp-contrast-light, 100)};
  background-color: #{map.get(comparit.$cp-secondary, 100)};
}

.error {
  color: #{map.get(comparit.$cp-contrast-light, 100)};
  background-color: #{map.get(comparit.$cp-error, 100)};
}

/**
 * Item Element
 */
.drag-item {
  border-radius: 4px;
  background-color: #ededf1;
  padding: 6px 12px;

  & + .drag-item {
    margin-top: 10px;
  }

  &.active {
    background-color: #{map.get(comparit.$cp-primary, 100)};
    color: #{map.get(comparit.$cp-contrast-light, 100)};

    .mat-mdc-checkbox .mdc-form-field {
      color: #{map.get(comparit.$cp-contrast-light, 100)};
    }
  }
}

/**
 * drag & drop 
 */
.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: shadow;
}

.cdk-drag-placeholder {
  border: 1px dashed #ededf1;
  background: transparent;
  * {
    opacity: 0;
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

//Remove arrows from number input start
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
//Remove arrows from number input end
