@use 'sass:map';
@use '@angular/material' as mat;
@use '../scss/vars/colors/comparit.scss';

// used for angular material theming
$md-primary: (
  50: #e4e4ea,
  100: #bbbbca,
  200: #8d8ea6,
  300: #5f6082,
  400: #3d3e68,
  500: #1b1c4d,
  600: #181946,
  700: #14143d,
  800: #101134,
  900: #080925,
  A100: #6269ff,
  A200: #2f39ff,
  A400: #000cfb,
  A700: #000be1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-accent: (
  50: #e2f6f4,
  100: #b6e9e5,
  200: #85dbd3,
  300: #54cdc1,
  400: #2fc2b4,
  500: #0ab7a7,
  600: #09b09f,
  700: #07a796,
  800: #059f8c,
  900: #03907c,
  A100: #bcfff4,
  A200: #89ffeb,
  A400: #56ffe3,
  A700: #3cffde,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-warn: (
    50 : #ffe8e0,
    100 : #ffc5b3,
    200 : #ff9e80,
    300 : #ff774d,
    400 : #ff5a26,
    500 : #ff3d00,
    600 : #ff3700,
    700 : #ff2f00,
    800 : #ff2700,
    900 : #ff1a00,
    A100 : #ffffff,
    A200 : #fff3f2,
    A400 : #ffc3bf,
    A700 : #ffaba6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mainColor: map.get($md-primary, 500);
$subColor: map.get($md-accent, 500);
$subColorLight: map.get($md-accent, 50);

$primaryContrast: #fff;
$accentContrast: #fff;
$warnContrast: #fff;

$shadow: 0 4px 24px 0 #{map.get(comparit.$cp-primary, 20)};
